.subscription-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 5px;
  text-align: left;
  .secondary-text {
    font-size: 12px;
  }

  .subscription-list-item-pay-button {
    height: 8px;
    border: 1px solid grey;
    font-size: 14px;
    padding: 12px;
    text-decoration: none;
    color: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: 35px;

    span {
      display: flex;
    }
  }
}
